<template>
  <div id="seamless-solutions">
    <div class="row text-image right-aligned" id="seamless-solutions">
      <!-- Seamless Solutions for Modern Offices -->
      <div
        class="col-md-6 image-content"
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-duration="500"
      >
        <img src="@/assets/about-us/img1.png" alt="Seamless Solutions"/>
      </div>
      <div
        class="col-md-6 text-content"
        data-aos="fade-left"
        data-aos-offset="300"
        data-aos-duration="500"
      >
        <div class="Aligner">
          <div class="Aligner-item Aligner-item--top"></div>
          <div class="Aligner-item">
            <img class="logo-gfx" src="@/assets/about-us/seamless.svg" alt="Ease Symbol"/>
            <h1>Seamless Solutions for Modern Offices</h1>
            <p>
              Ease Hospitality partners with commercial landlords and tenants,
              providing premium management solutions for amenities. Offerings
              include food &amp; beverage programs, meetings &amp; events
              facilities, and third–party managed office spaces both physically
              and virtually.
            </p>
          </div>
          <div class="Aligner-item Aligner-item--bottom"></div>
        </div>
      </div>
    </div>

    <!-- Meetings & Events -->
    <div class="row text-image left-aligned" id="meetings-and-events">
      <div
        class="col-md-6 order-md-2 image-content"
        data-aos="fade-left"
        data-aos-offset="300"
        data-aos-duration="500"
      >
        <img src="@/assets/about-us/img2.png" alt="About Us Photo 2"/>
      </div>
      <div
        class="col-md-6 order-md-1 text-content"
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-duration="500"
      >
        <div class="Aligner">
          <div class="Aligner-item Aligner-item--top"></div>
          <div class="Aligner-item">
            <router-link to="/meetings-and-events-spaces">
              <img class="logo-gfx" src="@/assets/about-us/meetings.svg" alt="Meeting Symbol"/>
              <h1>Meetings &amp; Events</h1>
              <p>
                Our dedicated planning professionals, including our technology
                experts, ensure seamless connectivity for your meetings and
                events. We play host to your every need, from concierge booking
                of hybrid &amp; virtual meetings to managing in-person
                state-of-the-art facilities, our experts pay attention to all of
                the fine details so you do not have to.
              </p>
            </router-link>
            <p>
              <router-link to="/in-person-spaces" class="generic-link"
                >LEARN MORE</router-link
              >
            </p>
          </div>
          <div class="Aligner-item Aligner-item--bottom"></div>
        </div>
      </div>
    </div>

    <!-- Full-Service Amenities Spaces -->
    <div
      class="row text-image right-aligned"
      id="full-service-amenities-spaces"
    >
      <div
        class="col-md-6 image-content"
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-duration="500"
      >
        <img src="@/assets/about-us/img3.png" alt="About Us Photo 3"/>
      </div>
      <div
        class="col-md-6 text-content"
        data-aos="fade-left"
        data-aos-offset="300"
        data-aos-duration="500"
      >
        <div class="Aligner">
          <div class="Aligner-item Aligner-item--top"></div>
          <div class="Aligner-item">
            <img class="logo-gfx" src="@/assets/about-us/full-service.svg" alt="Amenities Symbol"/>
            <h1>Full Space Amenities</h1>
            <p>
              We manage a range of full-service amenity offerings. Experience and commitment
              to excellence extends to everything we do. Customer service
              accompanies every tailored moment.
            </p>
          </div>
          <div class="Aligner-item Aligner-item--bottom"></div>
        </div>
      </div>
    </div>

    <!-- Workplace Solutions  -->
    <div class="row text-image left-aligned" id="workplace-solutions">
      <div
        class="col-md-6 order-md-2 image-content"
        data-aos="fade-left"
        data-aos-offset="300"
        data-aos-duration="500"
      >
        <img src="@/assets/about-us/img4.png" alt="About Us Photo 4"/>
      </div>
      <div
        class="col-md-6 order-md-1 text-content"
        data-aos="fade-right"
        data-aos-offset="300"
        data-aos-duration="500"
      >
        <div class="Aligner">
          <div class="Aligner-item Aligner-item--top"></div>
          <div class="Aligner-item">
            <img class="logo-gfx" src="@/assets/about-us/workplace.svg" alt="Workspace Symbol"/>
            <h1>Digital Offerings</h1>
            <p>
              Ease Hospitality is uniquely positioned to host and advise vendors
              on providing top–level productions. Ease knows no limits. Our
              equipped team thrives on third party management. Let us curate,
              connect, and crystallize your vision of a modern managed
              workplace.
            </p>
          </div>
          <div class="Aligner-item Aligner-item--bottom"></div>
        </div>
      </div>

    </div>

    <!-- Separator -->
    <img
      class="gradient-separator"
      aria-hidden="true"
      src="@/assets/about-us/gradient-separator.png" alt="Divider"
    />

    <!-- Meet our Team -->
    <div class="half-screen-grouped">
      <div class="row header-texts">
        <div class="col-md-6 header-item">
          <h1 class="meet-our-team-header">Meet Our Team</h1>
        </div>
        <div class="col-md-6 header-item">
          <p>
            The Ease Hospitality team is dedicated to providing our members and guests with
            exceptional service.
          </p>
        </div>
      </div>

<!--       <div class="gallery-type-section text-centered" data-aos="fade-in">
        <div class="row">
          <div class="col-md-12 full-width extra-vertical-margin bottom" data-aos-offset="600">
            <img src="@/assets/about-us/team-banner.png" />
          </div>
        </div>
      </div> -->

      <!-- TABS -->
      <!-- <div class="d-flex justify-content-center" style="margin-top: 65px">
        <div class="col-sm-2 d-flex justify-content-center">
          <h1
            class="tab-link"
            @click="filterMembers('all')"
            :class="{ tabselected: selectedGroup == 'all' }"
          >
            All
          </h1>
        </div>
        <div class="col-sm-2 d-flex justify-content-center">
          <h1
            class="tab-link"
            @click="filterMembers('Leadership')"
            :class="{ tabselected: selectedGroup == 'Leadership' }"
          >
            Leadership
          </h1>
        </div>
        <div class="col-sm-2 d-flex justify-content-center">
          <h1
            class="tab-link"
            @click="filterMembers('GM')"
            :class="{ tabselected: selectedGroup == 'GM' }"
          >
            GM
          </h1>
        </div>
        <div class="col-sm-2 d-flex justify-content-center">
          <h1
            class="tab-link"
            @click="filterMembers('Sales')"
            :class="{ tabselected: selectedGroup == 'Sales' }"
          >
            Sales
          </h1>
        </div>
        <div class="col-sm-2 d-flex justify-content-center">
          <h1
            class="tab-link"
            @click="filterMembers('Tech')"
            :class="{ tabselected: selectedGroup == 'Tech' }"
          >
            Tech
          </h1>
        </div>
        <div class="col-sm-2 d-flex justify-content-center">
          <h1
            class="tab-link"
            @click="filterMembers('Services')"
            :class="{ tabselected: selectedGroup == 'Sevices' }"
          >
            Services
          </h1>
        </div>
        <div class="col-sm-2 d-flex justify-content-center">
          <h1
            class="tab-link"
            @click="filterMembers('Events')"
            :class="{ tabselected: selectedGroup == 'Events' }"
          >
            Events
          </h1>
        </div>
      </div> -->

      <!-- Image Blocks templating -->
        <!-- Team members array -->
      <div id="image-blocks">
        <div
          v-for="(pair, pairidx) in getMemberTrios()"
          :key="`pair-${pairidx}`"
          class="row image-with-hp-3"
        >
          <div
            v-for="(member, memberIdx) in pair.members"
            :key="`member-${pairidx * 3 + memberIdx}`"
            class="col-6 col-md-4 image-box"
            data-aos="fade-up"
            data-aos-duration="900"
          >
            <div
              class="image-caption-container"
              @click="selectPerson(pairidx * 3 + memberIdx)"

            >
            <!-- v-b-modal.person-modal (move back into div above ^) -->
              <img :src="member.imageURL" alt="Member Photo"/>
              <h3>{{ member.name }}</h3>
              <p>{{member.title}}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- End of image blocks -->
    </div>

    <b-modal
      id="person-modal"
      content-class="person-modal"
      class="person-modal"
      centered
      v-if="this.filteredMembers[currentMemberIdx]"
    >
      <div class="row">
        <div class="col-md-12 image-content paginated">
          <carousel
            :per-page="1"
            :paginationEnabled="false"
            :ref="`carousel`"
            :value="currentMemberIdx"
          >
            <slide
              v-for="(member, index) in this.filteredMembers"
              :key="`image-${index}`"
            >
              <img :src="member.imageURL" alt="Member Photo Open"/>
            </slide>

            <!-- End of Carousel -->
          </carousel>
          <div class="paginator-arrows">
            <img
              @click="previousPerson"
              src="@/assets/about-us/left-arrow-circle.svg" alt="Previous"
            />
            <img
              @click="nextPerson"
              src="@/assets/about-us/right-arrow-circle.svg" alt="Next"
            />
          </div>
        </div>
        <div class="col-md-12 text-content">
          <div class="close-button">
            <img
              class="close-button"
              src="@/assets/navigation/close.svg"
              @click="closeModal"
              alt="Close"
            />
          </div>
          <div class="Aligner">
            <div class="Aligner-item Aligner-item--top"></div>
            <div class="Aligner-item">
              <h1>{{ this.filteredMembers[currentMemberIdx].name }}</h1>
              <h2 class="title">
                {{ this.filteredMembers[currentMemberIdx].title }}
              </h2>
              <p class="email">
                <a href="/" class="generic-link">{{
                  this.filteredMembers[currentMemberIdx].email
                }}</a>
              </p>
              <!-- <div class="sns-logo-containers">
                    <a href="https://www.facebook.com/Ease-Hospitality-106482987830969" class="sns-logo" target="_blank"><img src="@/assets/footer/footer-facebook-icon.svg" /></a>
                    <a href="https://www.instagram.com/easehospitality" class="sns-logo" target="_blank"><img src="@/assets/footer/footer-instagram-icon.svg" /></a>
                    <a href="https://www.linkedin.com/company/easehospitality/" class="sns-logo" target="_blank"><img src="@/assets/footer/footer-linkedin-icon.svg" /></a>
                    <a href="#" class="sns-logo" target="_blank"><img src="@/assets/footer/footer-twitter-icon.svg" /></a>
                </div> -->

              <!-- <img class="gradient-separator" aria-hidden="true" src="@/assets/about-us/gradient-separator.png" /> -->

              <p
                class="desc"
                v-for="(desc, descIdx) in this.filteredMembers[currentMemberIdx]
                  .description"
                :key="`desc-${descIdx}`"
              >
                {{ desc }}
              </p>
            </div>
            <div class="Aligner-item Aligner-item--bottom"></div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- We'd Love To -->
    <div class="text-full-width text-centered contact-section" data-aos="fade-in">
      <!-- Separator -->
      <img class="gradient-separator" src="@/assets/about-us/gradient-separator.png" alt="Divider"/>

      <div class="Aligner">
        <div class="Aligner-item Aligner-item--top"></div>
        <div class="Aligner-item">
          <h1>We'd Love To Hear From You</h1>
          <p>Please let us know how we can be of assistance.</p>
          <router-link to="/contact">Contact Us</router-link>
        </div>
        <div class="Aligner-item Aligner-item--bottom"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/page-body.scss";
.VueCarousel-inner {
  visibility: visible;
  flex-basis: 100%;
  width: 100%;
}

.modal-open {
  overflow-y: scroll;
}
.about-us-page {
  .text-content {
    a {
      color: $palm-green !important;
    }
  }
}

#seamless-solutions {
  a.generic-link {
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.2em;
    /* text-transform: lowercase; */
    border-bottom: 2px solid #eec6bb;
  }
}
@media only screen and (max-width: 1023px) {
  h1.meet-our-team-header {
    text-align: center !important;
    margin: 0 !important;
  }
}

#person-modal {
  .gradient-separator {
    width: 100%;
    height: 2px;
    margin: 32px auto 12px 0;
  }
  .modal-dialog {
    max-width: 600px;
  }
  .person-modal {
    background-color: $black;
    border-radius: 0;
    border-width: 0;
    .modal-header {
      display: none;
    }
    .modal-body {
      padding: 0;
      color: $ivory;
      .image-content {
        .VueCarousel {
          height: 100%;
          img {
            object-fit: cover;
            width: 100%;
            padding: 24px;
            padding-bottom: 0;
          }
        }
      }

      .title {
        font-size: 16px !important;
        color: rgba(#fff, 0.85);
      }
      .text-content {
        padding: 8%;
        p.desc {
          font-size: 18px;
          padding-top: 1em;
        }
        p.email {
          font-size: 16px;
          text-transform: uppercase;
          margin-bottom: 1em;

          & > a {
            font-weight: bold;
            font-size: 12px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            border-bottom: 2px solid #eec6bb;
            color: white;
          }
        }
      }
    }
    .sns-logo-containers {
      margin-top: 15px;
    }
    .modal-footer {
      display: none;
    }
  }
  /** Mobile */
  @media only screen and (max-width: 1023px) {
    .modal-body {
      .text-content {
        padding-top: 40px !important;
        padding-bottom: 20px !important;
        h1 {
          font-size: 30px !important;
          margin-bottom: 4px !important;
        }
        p.desc {
          font-size: 16px !important;
        }
        p.email {
          font-size: 16px !important;
        }
      }
    }
    /** End of Mobile */
  }
}
</style>

<script>
export default {
  name: "SeamlessSolution",
  data() {
    return {
      selectedGroup: "all",
      teamMembers: [
        // Lauren
        {
          name: `Lauren Tidey`,
          title: `Director of Brand Management`,
          description: [
            `Meet Lauren Tidey, Ease Hospitality’s Senior Planning Manager for their conference centers.`,

            `It was a position at a fine dining restaurant while at college that was Lauren’s introduction to the hospitality industry; and ultimately became a passion for a fulfilling career. Her corporate training taught her the foundation of the business and what true hospitality really means. She notes, “It’s creating memories for guests that really drives my motivation for the industry.”`,

            `After a few years in the fashion industry, Lauren returned to hospitality working for InterContinental Hotels Group(IHG) in Manhattan for over seven years. Starting as the Private Dining Sales Manager for Chef Todd English’s ÇaVa Brasserie restaurant, she discovered her love for events. Her responsibilities quickly broadened amongst the hotel’s Food and Beverage outlets. Later, she advanced to the conference department as the Associate Director of Catering and Conference Services. There, as the liaison amongst her clients and hotel departments, Lauren ensured the details of a group’s guestroom stay or multi-day conference was a success.`,

            `Not surprising that she became intrigued learning of the opportunities with Ease Hospitality, and is proud to be a member of the team stating, “There are many facets to true hospitality, where attention to detail and anticipating needs are paramount. We will do whatever it takes to ensure the needs of our clients are exceeded and that they have a positive, memorable experience. It truly is a rewarding industry.”`,
          ],
          email: `ltidey@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Leadership",
          imageURL: require(`@/assets/about-us/team/headshot-ltidey.png`),
        },
        // Nate
        {
          name: `Nate Ingram`,
          title: `Director of Sales`,
          description: [
            ``,
          ],
          email: `ningram@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Leadership",
          imageURL: require(`@/assets/about-us/team/headshot-ningram.png`),
        },
        // Nate
        {
          name: `Tom Yoon`,
          title: `Director of Technology`,
          description: [
            ``,
          ],
          email: `tyoon@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Leadership",
          imageURL: require(`@/assets/about-us/team/headshot-tyoon.png`),
        },
        // Dawys
        {
          name: `Dawys Estevez`,
          title: `General Manager, @Ease 605`,
          description: [
            `Dawys Estevez brings eight years of hospitality experience to his role as Service Manager with Ease Hospitality. With an extensive background in the hospitality industry, Dawys is a skilled relationship builder with a talent for envisioning the overarching strategy of large operations. Dawys enjoys working to ensure the effective completion of each function within the world-class @Ease hospitality offering. While previously working at Convene, Dawys was responsible for the financial health of the facility, the team’s professional development, and the execution of all functions across the service, production, culinary, and technology teams. As part of Convene’s founding team, Dawys supported the opening of their first Signature Facility and oversaw the launch of Convene’s first built-in Bar Cocktail Program.`,

            `In his role as Service Manager for Ease Hospitality, Dawys executes world class hospitality standards in accordance with the company’s innovative operating procedures. With day-to-day tasks running the gamut from managing the Service Team to supporting technology initiatives, Dawys occupies an integral role with the @Ease organization ensuring a seamless tenant experience. With a sharp attention to detail and a passion for the hospitality industry, Dawys is well-positioned to strategically support all aspects of @Ease.`,
          ],
          email: `destevez@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "GM",
          imageURL: require(`@/assets/about-us/team/headshot-destevez.png`),
        },
        // Marcos
        {
          name: `Marcos DeGiorgio`,
          title: `Senior Sales Manager`,
          description: [
            `As @Ease’s Senior Sales Manager, Marcos DeGiorgio is an integral part of the Ease Hospitality team. Marcos oversees the sales of @Ease 1345’s  luxury corporate meetings and events space, as well as their hybrid and virtual meeting services. After starting his career in hospitality as a snowboard and ski instructor, Marcos discovered his passion for the hospitality industry and customer service. At Stowe Mountain Resort in Vermont, Marcos worked his way up through the ticketing office to a Sales and Marketing position where he honed his sales expertise within the corporate meetings & events industry. After working in Vermont for two years, he returned to New York City with a sales job working for a meetings and events company in Manhattan prior to accepting a promotion at a hotel conference center in Westchester, NY. While working at Convene, Marcos supported the launch of One Liberty Plaza.`,

            `In his role as Senior Sales Manager, Marcos’ biggest challenge is educating clients about the ever-evolving safety of in-person meetings. Ease Hospitality provides safe, controlled environments paired with hybrid meeting offerings to accommodate every type of meeting need. Marcos and the Ease Hospitality team aim to protect, communicate, and cultivate strong relationships with guests and staff members that underscore the exceptional offering of the Ease Hospitality Brand.`,
          ],
          email: `mdegiorgio@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Sales",
          imageURL: require(`@/assets/about-us/team/headshot-mdegiorgio.png`),
        },
        // Tom
        // {
        //   name: `Tom Yoon`,
        //   title: `Technology Manager`,
        //   description: [
        //     `--`,
        //   ],
        //   email: `tyoon@easehospitality.com`,
        //   sns: {
        //     fb: "#",
        //     ig: "#",
        //     li: "#",
        //     tw: "#",
        //   },
        //   group: "Tech",
        //   imageURL: require(`@/assets/about-us/team/headshot-default.png`),
        // },
        // Mark
        {
          name: `Mark Harder`,
          title: `Senior Producer`,
          description: [
            `--`,
          ],
          email: `mharder@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Tech",
          imageURL: require(`@/assets/about-us/team/headshot-mharder.png`),
        },
        // Julio
        {
          name: `Julio Pena`,
          title: `Technician, @Ease 605`,
          description: [
            `--`,
          ],
          email: `jpena@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Tech",
          imageURL: require(`@/assets/about-us/team/headshot-jpena.png`),
        },
        // Jorge
        {
          name: `Jorge Hernandez`,
          title: `Service Manager, @Ease 605`,
          description: [
            `My career in Hospitality began at Convene as a part time service technician.  I was then promoted to full time within a year and finally Supervisor within 3 years.`,

            `I received plenty of recognition, and awards for my hard work and dedication to my job.  I also helped in opening of the corporate headquarters for the company, as well as one of its newest facilities.  When it comes to meetings, events, and amenities management my mentality is to provide the best possible experience I can for clients, usually by developing an open rapport with them to understand their wants and needs and making sure the facility is well maintained and always restocked.  The goal for me is to make sure clients want to come back whenever they need a space for meetings and spread the word to bring in more clients as well.`,
          ],
          email: `jhernandez@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Service",
          imageURL: require(`@/assets/about-us/team/headshot-jhernandez.png`),
        },
        // Maria
        {
          name: `Maria Taveras`,
          title: `Service Manager, @Ease 605`,
          description: [
            `--`,
          ],
          email: `mtaveras@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Service",
          imageURL: require(`@/assets/about-us/team/headshot-mtaveras.png`),
        },
        // Ashley
        {
          name: `Ashley Brouwer`,
          title: `Event Planner, @Ease 605`,
          description: [
            `--`,
          ],
          email: `abrouwer@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Events",
          imageURL: require(`@/assets/about-us/team/headshot-abrouwer.png`),
        },
        // Will
        {
          name: `Will Tobin`,
          title: `Account Manager`,
          description: [
            `--`,
          ],
          email: `wtobin@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Sales",
          imageURL: require(`@/assets/about-us/team/headshot-wtobin.png`),
        },
        // John
        {
          name: `John Ianniello`,
          title: `General Manager, @Ease 1345`,
          description: [
            `I knew from the first time that I set a table for an event that this industry was for me.
          Growing up in an Italian family, hosting dinners, holidays and special events was a part of everyday life and I immediately felt a connection when starting in this industry. I was always taught to treat everyone as a guest in your home and that idea could never be truer than in the hospitality world.`,

            `The idea of creating someone’s vision and then following through with the planning and execution of that vision has been what’s driven me to the hospitality industry for the past 25 years. Whether setting for a wedding of 400, a gala of 550, a socially distant bridal shower of 30 or a conference of 15, the players change but the message remains the same. The idea of anticipating your client’s needs and wants before they ask, or even know themselves, has kept me at the top of my game through many roles in this industry. As professionals in hospitality, we need to always focus on emotional engagement, service excellence and culinary innovation. By excelling in these three pillars, we will always be a leader in this always evolving industry. As long as I am part of a team, our clients will always be guests in our home. That’s what my mom always taught me and that’s how I lead my team.`,
          ],
          email: `jianniello@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "GM",
          imageURL: require(`@/assets/about-us/team/headshot-jianniello.png`),
        },
        // Stalyn
        {
          name: `Stalyn Rodriguez`,
          title: `Service Manager, @Ease 1345`,
          description: [
            `--`,
          ],
          email: `srodriguez@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Service",
          imageURL: require(`@/assets/about-us/team/headshot-srodriguez.png`),
        },
        // Stalyn
        {
          name: `Cindy Garcia`,
          title: `Service Manager, @Ease 1345`,
          description: [
            `--`,
          ],
          email: `cgarcia@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Service",
          imageURL: require(`@/assets/about-us/team/headshot-cgarcia.png`),
        },
        // Jennifer
        {
          name: `Jennifer Beauzile`,
          title: `Senior Event Planner, @Ease 1345`,
          description: [
            `--`,
          ],
          email: `jbeauzile@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Events",
          imageURL: require(`@/assets/about-us/team/headshot-jbeauzile.png`),
        },
        // Jasmine
        {
          name: `Jasmine Jackson`,
          title: `Technology Manager, @Ease 1345`,
          description: [
            `--`,
          ],
          email: `jjackson@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "Tech",
          imageURL: require(`@/assets/about-us/team/headshot-jjackson.png`),
        },
        // Chris
        {
          name: `Chris Provost`,
          title: `Wellness Manager`,
          description: [
            `I have been involved in the Fitness Industry managing, group training, and personal training for the past 10 years. As a child I was heavily influenced by my father's love for cycling and started competing in BMX. The love for the sport continued and grew into a competitive one. Eventually I was ranked one of top 15 year olds in the country. Tragedy struck when I shattered my leg a few years later, forcing me to leave a sport I found so much joy and passion for. After graduating college and moving to New York City I discovered Olympic Weightlifting and CrossFit. Soon after, I became a certified CrossFit Trainer and USA Weightlifting Coach, and my passion continued to grow. It was then I found joy again by teaching & helping others to understand their true potential.`,

            `In recent years I have switched gears from a competition mindset to one centered around health & longevity for both myself and my clients. I believe a smarter and more sustainable training approach is essential for supporting the movements of everyday life. My mission is to not only have people feel strong, confident, and accomplished after working out with me, but to also understand why movements must be done in certain ways. My positive energy, focus, and personal attention to detail are all driving forces in making that happen!`,

            `Certifications include: CF-L1, CF-L2, CF Anatomy, USAW Sports Performance Coach, CPR/AED`,
          ],
          email: `cprovost@easehospitality.com`,
          sns: {
            fb: "#",
            ig: "#",
            li: "#",
            tw: "#",
          },
          group: "1345",
          imageURL: require(`@/assets/about-us/team/headshot-cprovost.png`),
        },

      ],
      filteredMembers: [],
      currentMemberIdx: 0,
    };
  },
  mounted() {},
  methods: {
    filterMembers(group) {
      this.selectedGroup = group;
      console.log(group);
      if (group == "all") {
        this.filteredMembers = this.teamMembers;
        return;
      }
      this.filteredMembers = this.teamMembers.filter((member) => {
        return member.group.includes(group);
      });
    },

    getMemberPairs() {
      var pairs = [];

      var newpair = {
        members: [],
      };
      for (var i = 0; i < this.teamMembers.length; i++) {
        var teammember = this.teamMembers[i];
        if (i % 2 == 0) {
          // even, index 0, 2, 4, etc.
          //  aka, first person in pair
          newpair = {
            members: [teammember],
          };

          if (i == this.teamMembers.length - 1) {
            // is last member
            pairs.push(newpair);
          }
        } else {
          // odd, index 1, 3, 5, etc.
          //  aka, second person in pair
          newpair.members.push(teammember);
          pairs.push(newpair);
        }
      }

      return pairs;
    },
    getMemberTrios() {

      if(this.filteredMembers.length == 0){
        this.filterMembers(this.selectedGroup);
      }

      var trios = [];

      var newtrio = {
        members: [],
      };
      // var count = 0;

      for (var i = 0; i < this.filteredMembers.length; i++) {
        var teammember = this.filteredMembers[i];

        // if (count > 1) {
        //   console.log("testing1");
        //   newtrio.members.push(teammember);
        //   trios.push(newtrio);
        //   count = 0;
        //   newtrio = {
        //     members: [],
        //   };
        // } else {
          console.log("testing2");
          newtrio.members.push(teammember);
          if (i == this.filteredMembers.length - 1) {
            // is last member
            trios.push(newtrio);
          }
        //   count = count - 1;
        // }
      }

      console.log(trios.length);
      return trios;
    },

    selectPerson(idx) {
      setTimeout(() => {
        this.currentMemberIdx = idx;
        this.$refs["carousel"].onResize();
        this.$refs["carousel"].goToPage(idx);
      }, 0);
    },
    closeModal() {
      this.$bvModal.hide("person-modal");
      this.currentMemberIdx = 0;
    },
    previousPerson() {
      this.currentMemberIdx = Math.max(this.currentMemberIdx - 1, 0);
    },
    nextPerson() {
      this.currentMemberIdx = Math.min(
        this.currentMemberIdx + 1,
        this.filteredMembers.length - 1
      );
    },
    /** End of methods */
  },
};
</script>
